import React, { FC, useCallback } from 'react';
import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Link
} from '@chakra-ui/react';
import { Download } from 'react-feather';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';
import { VALID_EMAIL_REGEX } from 'ts/common/hooks/useClientCredentials';
import ModalButtonFooter from './ModalButtonFooter';

interface IDigitalDownloadEmailCredentialsModalProps {
    isDownloadAlbum: boolean;
    isDownloadAll: boolean;
    isDownloadPhoto: boolean;
    isSubjectToGdpr: boolean;
    onClose: () => void;
    onSubmit: (email: string, doesAcceptTerms: boolean) => void;
    $rootScope: {
        saveEmail: (email: string) => void;
    };
    translateFilter: SpAngularJs.ITranslateFilter;
}

const DigitalDownloadEmailCredentialsModal: FC<IDigitalDownloadEmailCredentialsModalProps> = ({
    isDownloadAlbum = false,
    isDownloadAll = false,
    isDownloadPhoto = false,
    isSubjectToGdpr = false,
    onClose,
    onSubmit,
    $rootScope: { saveEmail },
    translateFilter: t
}) => {
    const {
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        register
    } = useForm({ mode: 'onTouched' });

    const handleSuccessfulAccess = useCallback(
        ({ email, doesAcceptTerms }) => {
            saveEmail(email);
            onSubmit(email, doesAcceptTerms);
        },
        [onSubmit, saveEmail]
    );

    const getHeaderText = () => {
        if (isDownloadAlbum) {
            return t('downloadAlbumNoName');
        } else if (isDownloadAll) {
            return t('downloadAll');
        } else if (isDownloadPhoto) {
            return t('downloadPhoto');
        }

        return t('download');
    };

    return (
        <Box>
            <ModalHeader
                modalIcon={Download}
                headerText={getHeaderText()}
                headerSubText={t('enterDownloadEmail')}
            />
            <Flex
                as="form"
                flexDirection="column"
                paddingTop="32px"
                onSubmit={handleSubmit(handleSuccessfulAccess as SubmitHandler<FieldValues>)}
            >
                <Box paddingBottom="128px">
                    <FormControl isInvalid={Boolean(errors.email)} isRequired>
                        <FormLabel>{t('emailAddress')}</FormLabel>
                        <Input
                            {...register('email', {
                                required: t('emailAddressInvalid'),
                                pattern: {
                                    value: VALID_EMAIL_REGEX,
                                    message: t('emailAddressInvalid')
                                }
                            })}
                        />
                        {errors.email && (
                            <FormErrorMessage>{errors.email.message as string}</FormErrorMessage>
                        )}
                    </FormControl>
                    {isSubjectToGdpr && (
                        <FormControl
                            // This is necessary to override some existing Sass styles applied globally to labels:
                            sx={{ label: { margin: 0 } }}
                        >
                            <Checkbox
                                {...register('doesAcceptTerms', {
                                    validate: (value) => value === true
                                })}
                            >
                                {t('termsIAgree')}{' '}
                                <Link
                                    href="https://www.shootproof.com/legal/terms-of-use"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('termsOfUse')}
                                </Link>{' '}
                                {t('termsAnd')}{' '}
                                <Link
                                    href="https://www.shootproof.com/legal/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('privacyPolicy')}
                                </Link>{' '}
                                {t('termsForSite')}
                            </Checkbox>
                        </FormControl>
                    )}
                </Box>

                <ModalButtonFooter
                    isPrimaryDisabled={!isValid}
                    isLoading={isSubmitting}
                    secondaryButtonAction={onClose}
                    primaryButtonText={t('continue')}
                    primaryLoadingText={t('loading')}
                    secondaryButtonText={t('cancel')}
                />
            </Flex>
        </Box>
    );
};

export default DigitalDownloadEmailCredentialsModal;
