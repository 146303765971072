import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { Plus } from 'react-feather';
import {
    trackClientEventFromPriceSheetItems,
    useEvent,
    useGalleryApiFetch
} from 'ts/client/common';
import type { IAddItemToCartResponse, IImage } from 'ts/client/gallery/components';
import { EditCropSlide } from 'ts/client/gallery/components';
import { isSingleItem } from 'ts/client/gallery/components/common';
import { convertIEventPhotosToIImages } from 'ts/client/gallery/components/common';
import { useCurrency } from 'ts/client/gallery/hooks';
import { getCropStringFromCrop, getMaxCrop, getSize, noop, useTranslate } from 'ts/common';
import { SlidesSidePanel } from 'ts/common/components';
import { Button, GalleryHeading } from 'ts/common/components/gallery';
import { FetchMethod } from 'ts/common/hooks';
import type { ICrop } from 'ts/common/types';
import { EmailCaptureModal } from 'client_react/gallery/components/Store';
import { useStore } from 'client_react/gallery/hooks';
import { StoreModalProvider } from 'client_react/gallery/hooks/StoreModalContext';
import {
    AddedToCartSlide,
    Header,
    PhotosConfigSlide,
    SelectPhotosSlide
} from './select-photos-modal';

interface ISelectPhotosModal {
    isOpen: boolean;
    onClose: () => void;
    startingQuantity?: number;
}

const SelectPhotosModal: React.FC<ISelectPhotosModal> = ({ isOpen, onClose, startingQuantity }) => {
    const $ = useCurrency();
    const { id: eventId } = useEvent();
    const t = useTranslate('client.store.selectPhotosModal');
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });
    const {
        brandCurrencyCode,
        pathContext,
        photos,
        selectedPriceSheetGroup,
        selectedPriceSheetItem,
        spFulfilledDigitalItems,
        setUserSelectedPhotos,
        setUserSelectedProducts,
        userSelectedPhotos,
        userSelectedProducts
    } = useStore();
    const { settings } = useEvent();

    const isBuyingCanvas = selectedPriceSheetGroup?.groupType === 'canvas';
    const isCroppingAllowed =
        settings.allowCropping &&
        selectedPriceSheetItem !== null &&
        isSingleItem(selectedPriceSheetItem) &&
        getSize(selectedPriceSheetItem.size) !== null;
    const isSelfFulfilledDigital =
        selectedPriceSheetItem &&
        selectedPriceSheetItem.type === 'price-sheet-item-digital' &&
        !selectedPriceSheetItem?.isDigitalDownload;

    const generateUniqueKey = () => `${new Date().getTime()}-${Math.random()}`;

    const {
        isOpen: isEmailCaptureModalOpen,
        onClose: onEmailCaptureModalClose,
        onOpen: openEmailCaptureModal
    } = useDisclosure();
    const [goToPreviousSlide, setGoToPreviousSlide] = useState<() => void>(noop);
    const [goToSlide, setGoToSlide] = useState<(index: number) => void>(noop);
    const [selectedProductIndexToCrop, setSelectedProductIndexToCrop] = useState(0);

    const digitalItem = spFulfilledDigitalItems.find(
        (digitalItem) => digitalItem.id === String(selectedPriceSheetItem?.id)
    );

    const isSingleSpFulfulledDigital = digitalItem?.is_individual;

    const isLimitedPhotoSelectionActive = useMemo(() => {
        return isBuyingCanvas || isSelfFulfilledDigital;
    }, [isBuyingCanvas, isSelfFulfilledDigital]);

    const controlSubscribe = useCallback((goToSlide, goToPreviousSlide) => {
        setGoToSlide(() => goToSlide);
        setGoToPreviousSlide(() => goToPreviousSlide);
    }, []);

    const {
        loading: isPostToCartLoading,
        response: postToCartResponse,
        performFetch: postToCart
    } = useGalleryApiFetch<IAddItemToCartResponse>('cart/additems', {
        defer: true,
        method: FetchMethod.POST
    });

    const pushGoogleAnalyticsDataCallback = useCallback(() => {
        trackClientEventFromPriceSheetItems({
            eventSlug: 'add_to_cart',
            eventId,
            userSelectedProducts,
            currencyCode: brandCurrencyCode
        });
    }, [brandCurrencyCode, eventId, userSelectedProducts]);

    const handleAddItemsToCart = useCallback(() => {
        if (userSelectedProducts) {
            const items = userSelectedProducts.map((item) => {
                return {
                    album_id: item.albumContextId ?? '',
                    crop_data: item.cropData?.isChanged ? getCropStringFromCrop(item.cropData) : '',
                    photo_filter_id: item.image.filterBlackAndWhite ? '2' : '1',
                    photo_id: item.image.id,
                    price_sheet_item_id: item.priceSheetItem.id,
                    quantity: item.quantity
                };
            });

            postToCart({
                body: JSON.stringify({
                    items: items
                })
            });
        }
    }, [postToCart, userSelectedProducts]);

    const handleBuyAllPhotos = () => {
        const allPhotos = convertIEventPhotosToIImages(photos);

        const newSelectedPhotos = allPhotos.filter(
            (photo) => !userSelectedPhotos.some((selectedPhoto) => selectedPhoto.id === photo.id)
        );

        setUserSelectedPhotos((selectedPhotos) => [...selectedPhotos, ...newSelectedPhotos]);
        handleSelectPhotos(newSelectedPhotos);
        handleFinishSelectingPhotos();
    };

    const handleFinishSelectingPhotos = () => {
        if (isBuyingCanvas && isCroppingAllowed) {
            goToSlide(SlideIndex.EditCropSlide);
        } else {
            goToSlide(SlideIndex.PhotosConfig);
        }
    };

    const handleDeselectPhoto = (removedPhoto: IImage) => {
        setUserSelectedProducts((userSelectedProducts) =>
            userSelectedProducts.filter(
                (userSelectedProduct) => userSelectedProduct.image.id !== removedPhoto.id
            )
        );
    };

    const handleSelectPhotos = (selectedPhotos: IImage[]) => {
        if (selectedPriceSheetItem) {
            const newSelectedProducts = selectedPhotos.map((image) => {
                const maxCrop = getMaxCrop(
                    image,
                    isSingleItem(selectedPriceSheetItem)
                        ? getSize(selectedPriceSheetItem.size)
                        : null,
                    selectedPriceSheetItem.group.groupType === 'canvas',
                    selectedPriceSheetItem.group.groupType === 'ornaments'
                );

                return {
                    albumContextId:
                        pathContext.context === 'album' && pathContext.contextId
                            ? pathContext.contextId
                            : '',
                    cropData: maxCrop,
                    image: image,
                    key: generateUniqueKey(),
                    priceSheetItem: selectedPriceSheetItem,
                    quantity: startingQuantity || 1
                };
            });

            if (isLimitedPhotoSelectionActive) {
                setUserSelectedProducts(newSelectedProducts);
            } else {
                setUserSelectedProducts((userSelectedProducts) => [
                    ...userSelectedProducts,
                    ...newSelectedProducts
                ]);
            }
        }
    };

    const onEditCrop = (index: number) => {
        setSelectedProductIndexToCrop(index);
        goToSlide(SlideIndex.EditCropSlide);
    };

    const handleCanvasCrop = (crop: ICrop) => {
        setUserSelectedProducts((userSelectedProducts) => {
            crop.isChanged = true;
            userSelectedProducts[selectedProductIndexToCrop].cropData = crop;
            return userSelectedProducts;
        });
    };

    const onCropSet = (crop: ICrop) => {
        setUserSelectedProducts((userSelectedProducts) => {
            crop.isChanged = true;
            userSelectedProducts[selectedProductIndexToCrop].cropData = crop;
            return userSelectedProducts;
        });
        goToPreviousSlide();
    };

    useEffect(() => {
        if (postToCartResponse) {
            if (postToCartResponse.statusText === 'success') {
                onEmailCaptureModalClose();
                pushGoogleAnalyticsDataCallback();
                goToSlide(SlideIndex.AddedToCart);
            } else if (
                postToCartResponse.statusText === 'form_errors' &&
                postToCartResponse.need_email
            ) {
                openEmailCaptureModal();
            }
        }
    }, [
        goToSlide,
        onEmailCaptureModalClose,
        openEmailCaptureModal,
        postToCartResponse,
        pushGoogleAnalyticsDataCallback
    ]);

    const AddToCartButton = (
        <Button
            data-testid="add-to-cart-button"
            isDisabled={userSelectedProducts.length === 0 || isPostToCartLoading}
            onClick={handleAddItemsToCart}
            text={t('addToCart')}
            variant="primary"
            height="40px"
            width={!isMediumScreenOrLarger ? '100%' : '172px'}
        />
    );

    const BuyAllButton = (
        <Button
            data-testid="select-photos-buy-all-button"
            onClick={handleBuyAllPhotos}
            variant="ghost primary"
            text={t('buyAll')}
        />
    );

    const FinishSelectingPhotosButton = (
        <Button
            data-testid="select-photos-next-button"
            isDisabled={userSelectedPhotos.length === 0}
            onClick={handleFinishSelectingPhotos}
            text={t('next')}
            variant="primary"
            height="40px"
            width={!isMediumScreenOrLarger ? '100%' : '120px'}
        />
    );

    const ShouldAddToCartFromSelectPhotosSlide = useMemo(() => {
        return (
            isSingleSpFulfulledDigital ||
            (isBuyingCanvas && !isCroppingAllowed) ||
            isSelfFulfilledDigital
        );
    }, [isBuyingCanvas, isCroppingAllowed, isSelfFulfilledDigital, isSingleSpFulfulledDigital]);

    const getPageType = (context: string) => (context === 'favorites' ? 'Favorites' : 'Search');

    return (
        <StoreModalProvider goToSlide={goToSlide} goToPreviousSlide={goToPreviousSlide}>
            <EmailCaptureModal
                actionFunction={handleAddItemsToCart}
                isActionLoading={isPostToCartLoading}
                isOpen={isEmailCaptureModalOpen}
                onClose={onEmailCaptureModalClose}
            />
            <SlidesSidePanel
                controlSubscribe={controlSubscribe}
                onClose={onClose}
                isOpen={isOpen}
                slides={[
                    {
                        bodyStyles: { padding: '32px', zIndex: 0 },
                        content: (
                            <SelectPhotosSlide
                                handleSelectPhotos={handleSelectPhotos}
                                handleDeselectPhoto={handleDeselectPhoto}
                                page={getPageType(pathContext.context)}
                            />
                        ),
                        headingContent: (
                            <Header
                                headingText={
                                    !isMediumScreenOrLarger ? undefined : t('selectPhotos')
                                }
                                infoTextRight={
                                    isLimitedPhotoSelectionActive
                                        ? `${userSelectedPhotos.length}/1 ${t('selected')}`
                                        : !isMediumScreenOrLarger
                                        ? `${userSelectedPhotos.length} ${t('selected')}`
                                        : userSelectedPhotos.length === 1
                                        ? `${userSelectedPhotos.length} ${t('photoSelected')}`
                                        : `${userSelectedPhotos.length} ${t('photosSelected')}`
                                }
                                onClose={onClose}
                                primaryButton={
                                    !isMediumScreenOrLarger
                                        ? null
                                        : ShouldAddToCartFromSelectPhotosSlide
                                        ? AddToCartButton
                                        : FinishSelectingPhotosButton
                                }
                                secondaryButton={
                                    isLimitedPhotoSelectionActive || !isMediumScreenOrLarger
                                        ? null
                                        : BuyAllButton
                                }
                                showCloseButton
                                showContextSelect
                            />
                        ),
                        footer: !isMediumScreenOrLarger ? (
                            <Flex alignItems="center" width="100%" gap="20px">
                                {isLimitedPhotoSelectionActive ? null : (
                                    <Button
                                        data-testid="select-photos-buy-all-button"
                                        onClick={handleBuyAllPhotos}
                                        variant="outline primary"
                                        text={t('buyAll')}
                                        height="40px"
                                        width="100%"
                                    />
                                )}
                                {ShouldAddToCartFromSelectPhotosSlide
                                    ? AddToCartButton
                                    : FinishSelectingPhotosButton}
                            </Flex>
                        ) : undefined,
                        hideCloseButton: true,
                        size: isMediumScreenOrLarger ? 'full' : 'lg'
                    },
                    {
                        content: (
                            <PhotosConfigSlide
                                isCroppingAllowed={isCroppingAllowed}
                                onEditCrop={onEditCrop}
                            />
                        ),
                        headingContent: (
                            <Header
                                headingText={selectedPriceSheetGroup?.name}
                                infoTextLeft={`${userSelectedProducts.length} ${selectedPriceSheetItem?.name} ${selectedPriceSheetItem?.group?.name}`}
                                onClose={onClose}
                                primaryButton={!isMediumScreenOrLarger ? null : AddToCartButton}
                                secondaryButton={
                                    !isMediumScreenOrLarger ? null : (
                                        <Button
                                            data-testid="select-photos-buy-all-button"
                                            onClick={() => goToSlide(SlideIndex.SelectPhotos)}
                                            variant="ghost"
                                            text={t('addPhotos')}
                                            leftIcon={
                                                <Plus
                                                    width="14px"
                                                    height="14px"
                                                    style={{ marginRight: '2px' }}
                                                />
                                            }
                                        />
                                    )
                                }
                            />
                        ),
                        footer: !isMediumScreenOrLarger ? (
                            <Flex alignItems="center" width="100%" gap="20px">
                                {
                                    <Button
                                        data-testid="select-photos-buy-all-button"
                                        onClick={() => goToSlide(SlideIndex.SelectPhotos)}
                                        variant="outline primary"
                                        text={t('addPhotos')}
                                        height="40px"
                                        width="100%"
                                    />
                                }
                                {AddToCartButton}
                            </Flex>
                        ) : undefined,
                        hideCloseButton: true,
                        size: isMediumScreenOrLarger ? 'full' : 'lg'
                    },
                    {
                        content: userSelectedProducts &&
                            userSelectedProducts[selectedProductIndexToCrop] && (
                                <EditCropSlide
                                    crop={userSelectedProducts[selectedProductIndexToCrop].cropData}
                                    image={userSelectedProducts[selectedProductIndexToCrop].image}
                                    handleCanvasCrop={handleCanvasCrop}
                                    hideFooter={isBuyingCanvas && isCroppingAllowed}
                                    onCropSet={onCropSet}
                                    priceSheetItem={
                                        userSelectedProducts[selectedProductIndexToCrop]
                                            .priceSheetItem
                                    }
                                />
                            ),
                        headingContent: isBuyingCanvas && isCroppingAllowed && (
                            <Header
                                headingText={`
                                    ${selectedPriceSheetItem?.subgroup?.name} ${t('canvas')}
                                `}
                                infoTextLeft={
                                    selectedPriceSheetItem?.price
                                        ? $(selectedPriceSheetItem?.price)
                                        : undefined
                                }
                                onClose={onClose}
                                primaryButton={!isMediumScreenOrLarger ? null : AddToCartButton}
                            />
                        ),
                        footer: !isMediumScreenOrLarger ? AddToCartButton : undefined,
                        hideCloseButton: true,
                        size: isMediumScreenOrLarger ? 'full' : 'lg'
                    },
                    {
                        bodyStyles: { padding: '32px' },
                        content: <AddedToCartSlide onClose={onClose} />,
                        headingContent: (
                            <Flex alignItems="center">
                                <GalleryHeading
                                    text={t('addedToCart')}
                                    size="sm"
                                    variant="primary"
                                />
                            </Flex>
                        ),
                        hidePreviousButton: true
                    }
                ]}
                size="full"
            />
        </StoreModalProvider>
    );
};

export enum SlideIndex {
    SelectPhotos = 0,
    PhotosConfig = 1,
    EditCropSlide = 2,
    AddedToCart = 3
}

export default SelectPhotosModal;
