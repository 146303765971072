import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Text, useRadio, useRadioGroup, useTheme } from '@chakra-ui/react';

interface RadioButtonProps {
    children?: React.ReactNode;
    id?: string;
    value?: string | undefined;
    name?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    'aria-labelledby'?: string;
}

const RadioButton: FC<PropsWithChildren<RadioButtonProps>> = (props) => {
    const theme = useTheme();
    const { getInputProps, getCheckboxProps } = useRadio(props);

    return (
        <Box as="label" margin="0" height="100%" maxHeight="55px">
            <input {...getInputProps()} />
            <Box
                {...getCheckboxProps()}
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1px"
                borderRadius="4px"
                borderColor={
                    theme.isLightColorScheme
                        ? 'var(--chakra-colors-gray-300)'
                        : 'var(--chakra-colors-white)'
                }
                color={
                    theme.isLightColorScheme
                        ? 'var(--chakra-colors-gray-400)'
                        : 'var(--chakra-colors-white)'
                }
                cursor="pointer"
                data-testid="options-grid-button"
                fontSize="16px"
                fontWeight="var(--chakra-fontWeights-normal)"
                lineHeight="16px"
                _checked={{
                    color: theme.isLightColorScheme
                        ? 'var(--chakra-colors-white)'
                        : 'var(--chakra-colors-black)',
                    borderColor: 'brand.primary',
                    backgroundColor: 'brand.primary',
                    _hover: {
                        color: theme.isLightColorScheme
                            ? 'var(--chakra-colors-white)'
                            : 'var(--chakra-colors-black)'
                    }
                }}
                _hover={{ color: 'brand.primary', borderColor: 'brand.primary' }}
                height="100%"
                padding="6px"
                textAlign="center"
                whiteSpace="nowrap"
            >
                {props.children}
            </Box>
        </Box>
    );
};

interface RadioGroupGridProps {
    defaultValue?: number;
    fieldName: string;
    label?: string;
    onChange: (fieldName: string, value: number) => void;
    options: { name: string; value: number }[];
    parentFormValue?: number;
}

const RadioGroupGrid: FC<RadioGroupGridProps> = ({
    defaultValue,
    fieldName,
    label,
    onChange,
    options,
    parentFormValue
}) => {
    const theme = useTheme();
    const handleChange = useCallback(
        (value: string) => {
            onChange(fieldName, Number(value));
        },
        [fieldName, onChange]
    );

    const {
        value: currentRadioGroupValue,
        getRootProps,
        getRadioProps,
        setValue
    } = useRadioGroup({
        name: fieldName,
        defaultValue: defaultValue?.toString(),
        onChange: handleChange
    });

    const [numColumns, setNumColumns] = useState(1);
    const [maxTextWidth, setMaxTextWidth] = useState<number | null>(null);

    useEffect(() => {
        // Measure the width of the longest option name
        const maxWidth = options.reduce((max, option) => {
            const optionTextRef = document.createElement('div');
            optionTextRef.textContent = option.name;
            optionTextRef.style.position = 'absolute';
            optionTextRef.style.visibility = 'hidden';
            optionTextRef.style.fontSize = '16px';
            optionTextRef.style.fontWeight = 'var(--chakra-fontWeights-semibold)';
            optionTextRef.style.lineHeight = '16px';
            optionTextRef.style.padding = '10px';
            optionTextRef.style.whiteSpace = 'nowrap';
            document.body.appendChild(optionTextRef);

            const textWidth = getComputedStyle(optionTextRef).width;
            document.body.removeChild(optionTextRef);

            return Math.max(max, parseFloat(textWidth));
        }, 0);

        setMaxTextWidth(maxWidth);
    }, [options]);

    useEffect(() => {
        // Calculate the optimal number of columns based on the longest text width
        if (maxTextWidth) {
            const gridAvailableWidth =
                document.querySelector('#radio-group-grid')?.getBoundingClientRect().width || 0;
            const optimalColumns = Math.floor(gridAvailableWidth / maxTextWidth);

            setNumColumns(optimalColumns || 1);
        }
    }, [maxTextWidth, options]);

    //Ensure that the default option gets set when a size change
    //causes the options to change
    useEffect(() => {
        if (parentFormValue && currentRadioGroupValue !== parentFormValue) {
            setValue(parentFormValue.toString());
        }
    }, [currentRadioGroupValue, parentFormValue, setValue]);

    if (options.length === 0) {
        return null;
    }

    return (
        <>
            {label && (
                <Text
                    margin="24px 6px 2px 0"
                    color={
                        theme.isLightColorScheme
                            ? 'var(--chakra-colors-gray-500)'
                            : 'var(--chakra-colors-white)'
                    }
                    fontFamily="open-sans, sans-serif"
                    fontSize="16px"
                    fontWeight="var(--chakra-fontWeights-semibold)"
                    lineHeight="24px"
                    overflow="hidden"
                    whiteSpace="nowrap"
                >
                    {label}
                </Text>
            )}
            <Grid
                id="radio-group-grid"
                templateColumns={`repeat(${numColumns}, 1fr)`}
                alignItems="center"
                justifyContent="center"
                gap="8px"
                width={'100%'}
                gridAutoRows="1fr"
                {...getRootProps()}
            >
                {options.map(({ name, value }, index) => {
                    return (
                        <RadioButton key={index} {...getRadioProps({ value: value.toString() })}>
                            {name}
                        </RadioButton>
                    );
                })}
            </Grid>
        </>
    );
};

export default RadioGroupGrid;
